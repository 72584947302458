@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,700&display=swap&subset=latin-ext");

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  line-height: 1.2;
  color: #475059;
}

body.modal-open {
  overflow: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
}

h1 {
  font-size: 48px;
  color: #000000;
  font-weight: 100;

  @media only screen and (max-width: 620px) {
    font-size: 32px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
}

.dark h1 {
  color: #ffffff;
}

h2 {
  font-size: 72px;
  font-weight: 100;

  @media only screen and (max-width: 620px) {
    font-size: 48px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 36px;
  }
}

h3 {
  font-size: 24px;
  font-weight: 100;

  &.bigger {
    font-weight: 700;
  }
}

h4 {
  margin: 15px 0;
  font-size: 18px;
  font-weight: 700;
}

body {
  > .header {
    position: relative;
    width: 100%;
    padding-bottom: 51%;

    > .background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;

      img {
        min-width: 100%;
        min-height: 100%;
        max-width: 120%;
      }
    }

    > .image {
      position: absolute;
      top: 20px;
      bottom: 70px;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    @media only screen and (max-width: 500px) {
      padding-bottom: 70%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    > .title {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      h1 {
        margin-bottom: 2vw;
      }
    }
  }

  > .content {
    position: relative;
    max-width: 1200px;
    padding: 20px 50px 80px;
    margin: 100px auto 0;
    background-image: url("images/background.png");
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 1255px;

    .block {
      clear: both;
      width: 50%;
      float: right;
      padding: 7% 0;

      p {
        margin-top: 25px;
        max-width: 450px;

        > span {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      &:nth-child(even) {
        float: left;
      }
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }

    @media only screen and (max-width: 940px) {
      padding: 20px 10px 80px;
    }

    @media only screen and (max-width: 960px) {
      background-image: url("images/background-mobile.png");
      background-size: 750px;

      .block {
        width: 70%;
        padding: 8% 0;
      }
    }

    @media only screen and (max-width: 620px) {
      .block {
        width: 90%;
        padding: 14% 0;
      }
    }

    @media only screen and (max-width: 550px) {
      padding: 20px 25px 80px;

      .block {
        float: none;
        width: 100%;
        padding: 17% 0;

        &:nth-child(even) {
          float: right;
          text-align: right;
          max-width: 450px;
        }
      }
    }

    @media only screen and (max-width: 500px) {
      .block {
        padding: 20% 0;
      }
    }

    @media only screen and (max-width: 400px) {
      padding: 20px 15px 80px;

      .block {
        padding: 20% 0;
      }
    }

    @media only screen and (max-width: 380px) {
      background-size: 730px;

      .block {
        padding: 20% 0;
      }
    }
  }

  > .contact {
    text-align: center;
    margin-top: 30px;

    a {
      margin-top: 20px;
      font-weight: bold;
      color: #000000;
      text-decoration: none;
    }
  }

  > footer {
    margin-top: 100px;
    text-align: center;
    padding: 15px 125px 40px;

    @media only screen and (max-width: 960px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    h3 {
      margin-top: 15px;
    }

    .partners {
      display: flex;
      max-width: 1350px;
      justify-content: center;
      width: 100%;
      margin: 30px auto 0;
      flex-wrap: wrap;

      @media only screen and (max-width: 660px) {
        flex-direction: column;
        align-content: center;

        img {
          margin-top: 10px;
          align-self: center;
        }
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  color: #000000;

  > .wrapper {
    margin: 50px auto;
    position: relative;
    background-color: white;
    padding: 30px;
    max-width: 1250px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;

    > .left {
      border-radius: 5px 0 0 5px;
      flex: 1 1 100%;
      margin: -30px 30px -30px -30px;
      padding: 20px;

      background-image: url("./images/header-light.jpg");

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    > .right {
      display: flex;
      flex-direction: column;
      header {
        position: relative;
        padding-bottom: 20px;

        > button {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
          width: 32px;
          height: 32px;
          line-height: 30px;
          font-size: 18px;

          &:hover {
            opacity: 0.7;
          }
        }

        &:after {
          display: block;
          content: "";
          clear: both;
        }
      }

      .content {
        overflow: auto;
        p {
          margin-bottom: 10px;
        }

        li {
          margin-bottom: 5px;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      padding: 15px;
      margin: 20px;
      flex-direction: column;
      overflow: auto;

      > .left {
        margin: -15px -15px 15px -15px;
        border-radius: 5px 5px 0 0;
        text-align: center;

        img {
          width: 100%;
          max-width: 140px;
          max-height: 140px;
        }
      }
    }
  }

  &:not(.open) {
    display: none;
  }
}

.dark {
  .modal {
    > .wrapper {
      > .left {
        background-image: url("./images/header-dark.jpg");
        background-size: cover;
      }
    }
  }
}
